<template>
  <div>
    <div class="topNavBox">
      <div class="topbox clearfix">
        <a href="javaScript:" class="fl back" @click="seeBack">
          <img src="~img/back@2x.png" alt="" />
        </a>
        <p class="fl">{{ bookInfo.name }}</p>
        <a
          href="javaScript:"
          class="fr more clearfix"
          v-show="!isEnd"
          @click="startList"
        >
          <img class="fl" src="~img/book/read_zx@2x.png" alt="" />
          <span class="fl">
            倒序
          </span>
        </a>
        <a
          href="javaScript:"
          v-show="isEnd"
          class="fr more clearfix"
          @click="startList"
        >
          <img class="fl" src="~img/book/read_dx@2x.png" alt="" />
          <span class="fl">
            正序
          </span>
        </a>
      </div>
    </div>
    <!-- 閱讀記錄 -->
    <router-link
      :to="'/book/' + bookInfo.id + '/' + hisChapters.id"
      v-if="hisChapters"
      class="clearfix history"
    >
      <div class="fl clearfix left">
        <img class="fl" src="~img/book/history@2x.png" alt="" />
        <p class="fl">上次閱讀：{{ hisChapters.name }}</p>
      </div>
      <div class="fr clearfix right">
        <p class="fl">繼續閱讀</p>
        <img class="fl" src="~img/book/right.png" alt="" />
      </div>
    </router-link>
    <!-- 目錄 -->
    <ul class="menu">
      <li v-for="item in bookDirectory" :key="item.id">
        <a
          class="voName clearfix"
          href="javaScript:"
          @click="isOpenChapter(item.id)"
        >
          <div class="fl imgbox">
            <img
              v-show="item.isOpen"
              class="one"
              src="~img/book/common_unfold@2x.png"
              alt=""
            />
            <img
              v-show="!item.isOpen"
              class="two"
              src="~img/book/common_fold@2x.png"
              alt=""
            />
          </div>

          <p class="fl">{{ item.name }}</p>
        </a>
        <ul class="chapter" v-show="item.isOpen">
          <li
            v-for="chapter in item.chapters"
            :key="chapter.id"
            class="clearfix"
          >
            <a
              href="javaScript:"
              class="ellipsis"
              @click="goChapter(chapter.id, chapter.free)"
              >{{ chapter.name }}
            </a>
            <img
              class="fr"
              v-if="!chapter.free"
              src="~img/book/vip@2x.png"
              alt=""
            />
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { Bookmenu } from "apiurl/book";
import { readstatus } from "apiurl/user";
import { getToken } from "utils/storage";
export default {
  data() {
    return {
      isEnd: false,
      bookDirectory: [],
      bookInfo: {},
      firstchapter: {},
      isOpen: true,
      hisChapters: null
    };
  },
  created() {
    this.$nextTick(() => {
      this.bookId = this.$route.params.id;
      // 獲取書籍目錄
      this.BookMenu();
      if (getToken()) {
        // 歷史記錄
        this.getReStatus();
      }
    });
  },
  watch: {
    $route(to) {
      this.bookId = to.params.id;
      return this.BookMenu();
    }
  },
  methods: {
    seeBack() {
      this.$router.go(-1);
    },
    startList() {
      this.isEnd = !this.isEnd;
      this.bookDirectory = this.bookDirectory.reverse();
      this.bookDirectory.forEach((item, index) => {
        item.chapters = item.chapters.reverse();
        item.isOpen = false;
        if (index == 0) {
          item.isOpen = true;
        }
      });
    },
    // 獲取讀者閱讀狀態
    async getReStatus() {
      let res = await readstatus({ bookId: this.bookId });
      if (res.success) {
        this.hisChapters = res.data.hisChapter;
      }
    },
    // 獲取書籍目錄
    async BookMenu() {
      let res = await Bookmenu({ bookId: this.bookId });
      if (res.success) {
        this.bookInfo = res.data.book;
        res.data.menu.forEach((item, index) => {
          if (index == 0) {
            item.isOpen = true;
          } else {
            item.isOpen = false;
          }
        });
        this.bookDirectory = res.data.menu;
        this.firstchapter = this.bookDirectory[0].chapters[0];
      }
    },
    // 是否展開
    isOpenChapter(id) {
      this.bookDirectory.forEach(item => {
        if (item.id === id) {
          item.isOpen = !item.isOpen;
        }
      });
    },
    goChapter(id, free) {
      if (free) {
        localStorage.setItem("isfree", true);
      } else {
        localStorage.setItem("isfree", false);
      }
      this.$router.push(`/book/${this.bookInfo.id}/${id}`);
    }
  }
};
</script>

<style lang="stylus" scoped>
.topbox
    height 100px
    line-height 100px
    padding 0 27px
    position relative
    background rgba(255, 255, 255, 1)
    box-shadow 0 0 0 0 rgba(0, 0, 0, 0.05)
    .more
        color #555555
        font-size 30px
        img
            width 36px
            height 36px
            margin-top 32px
            margin-right 7px
        span
            display inline-block
            height 100%
            color #999999
    .back
        width 50px
        height 37px
        line-height 100px
        margin-top 13.5px
        img
            width 18px
            height 37px
    p
        position absolute
        left 0px
        top 0px
        bottom 0px
        right 0px
        margin auto
        text-align center
        font-size 34px
        color #333333
        width 70%
.history
    height:76px;
    line-height 76px
    background:rgba(250,250,250,1);
    font-size:24px;
    color:rgba(153,153,153,1);
    display block
    .left
        margin-left 24px
        img
            width 28px
            height 28px
            margin-top 24px
            margin-right 9px
    .right
        margin-right 24px
        color #298FF3
        img
            width 13px
            height 22px
            margin-top 27px
            margin-left 14px
.menu
    li
        .voName
            font-size:30px;
            color #333
            height 82px
            line-height 82px
            padding-left 24px
            display block
            font-size:30px;
            border-bottom:2px solid rgba(238,238,238,1);
            .imgbox
                width 20px
                height 20px
                margin-right 10px
                img
                    width 20px
                    height 10px
                    &.two
                        width 10px
                        height 20px
        .chapter
            display block
            li
                padding-left 58px
                height 82px
                line-height 82px
                border-bottom:2px solid rgba(238,238,238,1);
                a
                    color:rgba(153,153,153,1);
                    font-size 28px
                    width 80%
                    overflow hidden
                    display block
                img
                    width 28px
                    height 28px
                    margin 29px 32px 0 0
</style>
